<template>
  <div class="train train-img-w">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="train-cont">
      <div class="left">
        <div class="left-top" >
          <div v-if="!infoLoading">
            <div class="tit">
              <span>{{$t('trainingcamp_train_second')}}{{curIndex + 1}}{{ $t('trainingcamp_train_topic') }}</span>
              <span>{{ typesObj[dataInfo.datasetData.type]}}{{ $t('trainingcamp_train_topic') }}</span>
              <span v-if="dataInfo.datasetData.type != 1 && dataInfo.datasetData.type != 2 && dataInfo.datasetData.type != 3">({{ $t('trainingcamp_train_no_answer') }})</span>
            </div>
            <div class="name" style="display: inline-block;">
              <Richtxt :dataObj="{val:dataInfo.datasetData.name}" :isInline="true" :isDisabled="true"></Richtxt>
<!--              {{ dataInfo.datasetData.name }}-->
            </div>
            <div class="cont" v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3 || dataInfo.datasetData.type == 4">
              <!--        单选-->
              <RadioGroup v-model="radio" vertical v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 3" >
                <Radio :disabled="!modifiable" :label="optionList[index] + ': ' + item.name" class="option" :class="{'little-font-size':userSetting.font_size == '1','middle-font-size':userSetting.font_size == '0','big-font-size':userSetting.font_size == '2'}" v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                  <div  style="display: inline-block"><p style="display: flex;justify-content: flex-start"><span>{{String.fromCharCode(index+65)}}:</span>
                    <span style="display: inline-block;">
                      <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
                    </span>
                  </p></div>
                </Radio>
              </RadioGroup>
              <!--          多选-->
              <CheckboxGroup v-model="checkbox" v-if="dataInfo.datasetData.type == 2">
                <Checkbox :disabled="!modifiable" :label="optionList[index] + ': ' + item.name" class="checkoutOption" :class="{'little-font-size':userSetting.font_size == '1','middle-font-size':userSetting.font_size == '0','big-font-size':userSetting.font_size == '2'}"  v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                  <div  style="display: inline-block"><p style="display: flex;justify-content: flex-start"><span>{{String.fromCharCode(index+65)}}:</span>
                    <span style="display: inline-block;">
                      <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
                    </span>
                  </p></div>
                </Checkbox>
              </CheckboxGroup>
<!--              填空-->
              <div v-if="dataInfo.datasetData && dataInfo.datasetData.type == 4">
                <Input class="cont-input" disabled size="large" v-model="item.myAnswer" v-for="(item,index) in dataInfo.datasetDataOptions" :key="item.id">
                  <span slot="prepend">{{index + 1}}</span>
                </Input>
              </div>
            </div>
            <div class="cont" v-else-if="dataInfo.datasetData.type == 9">
<!--              <el-upload :action="constant.URL + '/uploadfile/upload'"-->
<!--                         :data="{-->
<!--                      type:3-->
<!--                    }"-->
<!--                         :headers="{-->
<!--                      'access-token':Cookies.get('liteAccessToken')-->
<!--                    }"-->
<!--                         :on-success="uploadFileSuccess"-->
<!--                         :show-file-list="false"-->
<!--                         :disabled="!modifiable"-->
<!--                         v-if="modifiable"-->
<!--              >-->
<!--                <div class="upload-btn">-->
<!--                  <Button type="primary">上传文件</Button>-->
<!--                </div>-->
<!--              </el-upload>-->
              <Button type="primary" v-if="uploadPath" @click="downLoad(uploadPath)">下载文件</Button>
              <p class="mt10" v-if="uploadName">文件地址：{{uploadPath}}</p>
            </div>
            <div class="cont" v-else>
              <Richtxt placeholder="请输入内容" :isDisabled="!modifiable" :height="150" :dataObj="{val:shortAnswer}"></Richtxt>
            </div>
            <div class="bot">
              <div class="btn">
                <Button @click="pre">{{$t('trainingcamp_train_pre')}}</Button>
                <Button @click="next">{{ $t('trainingcamp_train_next') }}</Button>
              </div>
              <div class="op">
                <div @click="collect">
                  <img src="../../assets/images/icon/star_active.png"  v-if="dataInfo.userCollect.id" />
                  <img src="../../assets/images/icon/star.png" v-else />
                  <span>{{ $t('trainingcamp_train_collection') }}</span>
                </div>
                <!--            <div>-->
                <!--              <img src="../../assets/images/icon/mark.png" />-->
                <!--              <span>标记</span>-->
                <!--            </div>-->
<!--                <div @click="feedback">-->
<!--                  <img src="../../assets/images/icon/report.png" />-->
<!--                  <span>{{$t('trainingcamp_train_feedback')}}</span>-->
<!--                </div>-->
                <div @click="correction">
                  <img src="../../assets/images/icon/mark.png" />
                  <span>{{ $t('trainingcamp_train_correction') + '/' + $t('trainingcamp_train_feedback') }}</span>
                </div>
              </div>
            </div>
          </div>
          <Spin fix v-else></Spin>
        </div>
        <div class="analysis" v-if="dataInfo.userData && dataInfo.userData.answer && !userSetting.quick_view">
          <div class="answer" v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3">
            {{ $t('trainingcamp_train_stu_answer') }}：<span :class="resultInfo.stuAnswer == resultInfo.correctAnswer ? 'green' :'red'">{{resultInfo.stuAnswer}}</span>
          </div>
          <div class="answer" v-if="resultInfo.correctAnswer">
            {{ $t('trainingcamp_train_correct_answer') }}：<span class="blue">{{resultInfo.correctAnswer}}</span>
          </div>
          <div class="cont">
            <span>{{ $t('trainingcamp_train_analysis') }}：</span>
            <span style="display: inline-block;">
              <Richtxt :dataObj="{val:resultInfo.explain}" :isInline="true" :isDisabled="true"></Richtxt>
            </span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="statistics">
          <div class="tit">{{trainInfo.name}}</div>
          <div class="time">{{ $t('trainingcamp_train_duration') }}：
            <p v-if="userUseTime.hours"><span>{{userUseTime.hours}}</span>{{ $t('trainingcamp_train_hour') }}</p>
            <p v-if="userUseTime.minutes"><span>{{userUseTime.minutes}}</span>{{ $t('trainingcamp_train_minute') }}</p>
            <p><span>{{userUseTime.seconds || 0}}</span>{{ $t('trainingcamp_train_time_second') }}</p>
          </div>
          <div class="data">
            <p class="val">{{ accuracy }} %</p>
            <p class="name">{{$t('trainingcamp_train_accuracy')}}</p>
            <div class="data-count">
              <div>
                <p>{{answerCardList.length}}</p>
                <p>{{ $t('trainingcamp_train_total') }}</p>
              </div>
              <div>
                <p class="green">{{trainUser.right_count || 0}}</p>
                <p>{{ $t('trainingcamp_train_right') }}</p>
              </div>
              <div>
                <p class="red">{{Number(trainUser.have_finished) || 0 - Number(trainUser.right_count) || 0}}</p>
                <p>{{ $t('trainingcamp_train_error') }}</p>
              </div>
              <div>
                <p>{{answerCardList.length - trainUser.have_finished || 0}}</p>
                <p>{{ $t('trainingcamp_train_unanswered') }}</p>
              </div>
            </div>
          </div>

        </div>
        <div class="right-top">
          <div class="tit">
            <div>
              {{ $t('trainingcamp_train_answer_sheet') }}
              <Tooltip>
                <Icon type="ios-help-circle-outline" />
                <div slot="content">
                  <p>{{ $t('trainingcamp_train_white') }}</p>
                  <p>{{ $t('trainingcamp_train_blue') }}</p>
                  <p>{{ $t('trainingcamp_train_green') }}</p>
                  <p>{{ $t('trainingcamp_train_red') }}</p>
                </div>
              </Tooltip>
            </div>
            <div>
              <i-switch v-model="onlyWrong" @on-change="changeOnlyWrong" />
              {{ $t('trainingcamp_train_only_error') }}
            </div>
          </div>
          <div class="answer-card">
            <div v-if="!answerCardLoading" class="list">
              <div :class="{active:curData.id == item.id,error:item.userData && item.userData.is_right == '0',corret:item.userData && item.userData.is_right == '1'} " v-for="(item,index) in answerCardList" :key="item.id" @click="changeAnswerCard(item,index)">{{index + 1}}</div>
            </div>
            <Spin fix v-else></Spin>
          </div>
        </div>
      </div>
    </div>

<!--    <Modal-->
<!--        v-model="feedbackModal"-->
<!--        :title="$t('trainingcamp_train_feedback')"-->
<!--        width="70%"-->
<!--    >-->
<!--      <Form ref="formValidate" :model="formItem" :label-width="80" :rules="ruleValidate">-->
<!--        <FormItem :label="$t('trainingcamp_train_feedback_tit')" prop="title">-->
<!--          <Input v-model="formItem.title" :placeholder="$t('trainingcamp_train_feedback_tit_placeholder')"></Input>-->
<!--        </FormItem>-->

<!--        <FormItem :label="$t('trainingcamp_train_feedback_cont')" prop="feedback">-->
<!--          <div class="feedback-richtxt">-->
<!--            <Richtxt :dataObj="{val:formItem.feedback}" @saveData="saveRichData"></Richtxt>-->
<!--          </div>-->
<!--          &lt;!&ndash;        <Input v-model="formItem.feedback" :maxlength="2000"  type="textarea"  placeholder="请输入反馈内容"></Input>&ndash;&gt;-->
<!--        </FormItem>-->
<!--      </Form>-->
<!--      <div slot="footer">-->
<!--        <Button @click="feedbackModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>-->
<!--        <Button type="primary" @click="confirm">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>-->
<!--      </div>-->
<!--    </Modal>-->
    <Modal
        v-model="correctionModal"
        :title="$t('trainingcamp_train_correction') + '/' + $t('trainingcamp_train_feedback')"
        width="70%"
    >
      <div class="correction">
        <div class="tit">
          <div class="left">
            <p class="data-tit">纠错标题</p>
          </div>
          <div class="right" style="border: none">
            <div class="right-list">
              <div :class="item.id == correctionTitle ? 'active' : ''" v-for="(item,index) in titleList" :key="index" @click="changeCorrectionTit(item.id)">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="tit">
          <div class="left">
            <p class="data-tit">纠错内容</p>
          </div>
          <div class="right">
            <Richtxt v-if="correctionModal" placeholder="请您描述具体错误原因。" :height="150" :dataObj="{val:formItem.feedback}" @saveData="saveRichData"></Richtxt>
          </div>
        </div>
        <p class="type"><strong>{{ $t('trainingcamp_train_correction_type') }}:</strong>{{ correctionInfo.datasetData && typesObj[correctionInfo.datasetData.type]}}</p>
        <div class="tit">
          <div class="left">
            <p class="data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>
          </div>
          <div class="right">
            <Richtxt v-if="correctionInfo.datasetData" :height="150" :dataObj="{val:correctionInfo.datasetData && correctionInfo.datasetData.name}" @saveData="(data)=>saveCurData(data,correctionInfo.datasetData,'name')" key="feedback"></Richtxt>
          </div>
        </div>
        <div class="option-item" v-for="(item,index) in correctionInfo.datasetDataOptions" :key="item.id">
          <div class="left">
            <p class="data-tit">{{optionNameList[index]}}</p>
            <p class="correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>
            <p class="dele" v-if="correctionInfo.datasetDataOptions.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>
          </div>
          <div class="right">
            <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>
          </div>
        </div>
        <Button type="primary" @click="addOption">{{ $t('trainingcamp_train_correction_add_option') }}</Button>
        <div class="analysis">
          <div class="left">
            <p class="data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>
          </div>
          <div class="right">
            <Richtxt v-if="correctionInfo.datasetData" @saveData="(data)=>saveCurData(data,correctionInfo.datasetData,'explain')" :height="150" :dataObj="{val:correctionInfo.datasetData && correctionInfo.datasetData.explain}" ></Richtxt>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button @click="correctionModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
        <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Richtxt from '../../components/production/richtxt.vue';
import FileSaver from "file-saver";
export default {
  name: "train.vue",
  data(){
    return {
      radio:'',
      switch1:true,
      value:'0',
      recordId:'',
      productId:2, //运营产品 ID ，小程序 1，阿里-每日一练 2
      answerCardList:[],
      answerCardLoading:true,
      curData:{}, //当前选中数据id
      curIndex:0,
      dataInfo:{},  //数据详情
      infoLoading:true,
      typesObj:'',
      optionList:['A','B','C','D','E','F','G','H','I','J','K','L'],
      startTime:'',
      endTime:'',
      resultInfo:{
        explain:'',
        stuAnswer:'',
        correctAnswer:''
      },
      modifiable:false,
      checkbox:[],
      feedbackModal:false,
      formItem:{
        title:'',
        feedback:'',
      },
      ruleValidate: {
        title:[{ required: true, message: this.$t('trainingcamp_train_feedback_tit_placeholder'), trigger: 'blur' }],
        feedback: [
          { required: true, message: this.$t('trainingcamp_train_feedback_cont_placeholder'), trigger: 'blur' }
        ]
      },
      userSetting:{},
      infoCorrectAnswer:'',
      onlyWrong:false,
      trainInfo:{},
      trainUser:{},
      userUseTime:{},
      breadCrumbList: [],
      correctionModal:false,
      correctionInfo:{},
      correctionTitle:'',
      titleList:[],
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      shortAnswer:'',
      uploadName:'',
      uploadPath:'',
    }
  },
  components:{
    Richtxt
  },
  computed:{
    accuracy:function(){
      if( this.trainUser.right_count && this.trainUser.right_count != '0'){
        return (this.trainUser.right_count / this.trainUser.have_finished * 100).toFixed(2)
      }else{
        return 0;
      }
    }
  },
  created(){
    this.recordId = this.$route.query.id;
    this.getAnswerCard();
  },
  methods:{
    getAnswerCard(){
      let params = {
        action:'practiceReport',
        train_user_id:this.recordId,
        product_id:this.productId,
        only_wrong:this.onlyWrong ? '1' : '0'
      };
      this.answerCardLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.answerCardLoading = false;
        if(res.data_list.length){
          this.answerCardList = res.data_list;
          this.typesObj = res.types;
          this.curData =  this.answerCardList[0];
          this.curIndex =  0;
          this.trainInfo = res.train;
          this.trainUser = res.train_user;

          this.userUseTime = util.timeFormate(res.train_user.use_time);

          this.titleList = [];
          for(let name in res.data_correction_types){
            this.titleList.push({
              name:res.data_correction_types[name],
              id:name,
            })
          }

          this.getInfo(); //获取数据详情
        }else{
          this.$nextTick(()=>{
            this.onlyWrong = false;
          })

          this.$Message.warning(this.$t('trainingcamp_train_no_data'));

        }
      }).catch((err)=>{
        this.answerCardLoading = false;
      })
    },
    getInfo(){  //获取详情数据
      let params = {
        action:'testListInfo',
        product_id:this.productId,
        group_id:this.curData.train_id,
        data_id:this.curData.data_id,
      };
      this.infoLoading = true;
      this.api.dataset.trainExecute(params).then((res)=>{
        this.infoLoading = false;
        this.dataInfo = res[0];
        this.formatInfoData(res[0]);

      }).catch((err)=>{
        this.infoLoading = false;
      })
    },
    getTime(){
      let oDate = new Date();
      let time = Math.floor(oDate.getTime() / 1000);
      console.log(time,'time')
      return time
    },
    resetSelectAnswer(){
      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3') {  //1单选 3判断
        this.radio = '';
      }
      if(this.dataInfo.datasetData.type == '2') {  //2多选
        this.checkbox = [];
      }
      this.$set(this.resultInfo, 'stuAnswer', '');
      this.$set(this.resultInfo, 'correctAnswer', '');
    },
    changeAnswerCard(data,index){
      // this.modifiable = true;
      this.curData = data;
      this.curIndex = index;

      this.resetSelectAnswer(); //清空选择项
      this.getInfo(); //获取数据详情
    },
    next(){ //下一题
      if(this.curIndex == this.answerCardList.length - 1){
        this.$Message.warning(this.$t('trainingcamp_train_last_test'));
        return;
      }
      this.curIndex = this.curIndex + 1;
      // this.modifiable = true;
      this.curData = this.answerCardList[this.curIndex];

      this.resetSelectAnswer(); //清空选择项
      this.getInfo(); //获取数据详情
    },
    pre(){  //上一题
      if(this.curIndex == 0){
        this.$Message.warning(this.$t('trainingcamp_train_first_test'));
        return;
      }
      this.curIndex = this.curIndex - 1;
      // this.modifiable = true;
      this.curData = this.answerCardList[this.curIndex];

      this.resetSelectAnswer(); //清空选择项
      this.getInfo(); //获取数据详情
    },
    formatInfoData(data,type){
      this.resultInfo.explain = data.datasetData.explain;
      let is_right;
      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3') {  //1单选 3判断

        let index = data.datasetDataOptions.findIndex((item) => {
          return data.userData.answer == item.id
        });
        let correctIndex = data.datasetDataOptions.findIndex((item) => {
          return item.is_correct == 1;
        });

        this.$set(this.resultInfo, 'stuAnswer', this.optionList[index]);
        this.$set(this.resultInfo, 'correctAnswer', this.optionList[correctIndex]);

        if(this.userSetting.quick_view){ //背题模式  获取详情的时候需要
          if(!type){
            this.infoCorrectAnswer = this.optionList[correctIndex];
            this.radio = this.optionList[correctIndex] + ': ' + data.datasetDataOptions[correctIndex].name;
          }

        }else{
          if(data.userData.answer){
            this.radio = this.optionList[index] + ': ' + data.datasetDataOptions[index].name;
          }

        }
      }


      if(this.dataInfo.datasetData.type == '2') {  //2多选
        let answerArr = data.userData.answer && data.userData.answer.split(',');
        //学生答案的index  正确答案的index
        let stuAnswerIndex = [];
        let correctAnswerIndex = [];

        data.datasetDataOptions.forEach((item,index)=>{
          if(answerArr){
            let num = answerArr.findIndex((sItem)=>{
              return sItem == item.id
            });
            if(num > -1){
              stuAnswerIndex.push(index);
            }
          }

          if(item.is_correct == '1'){
            correctAnswerIndex.push(index);
          }
        })

        let stuAnswer = [];
        let checkoutArr = [];
        let correctCheckoutArr = [];

        stuAnswerIndex.forEach((item)=>{
          stuAnswer.push(this.optionList[item])
          checkoutArr.push( this.optionList[item] + ': ' + data.datasetDataOptions[item].name)
        })

        let correctAnswer = [];
        correctAnswerIndex.forEach((item)=>{
          correctAnswer.push(this.optionList[item]);
          correctCheckoutArr.push(this.optionList[item] + ': ' + data.datasetDataOptions[item].name)
        });



        if(stuAnswer.length){
          this.$set(this.resultInfo, 'stuAnswer', stuAnswer.join(','));
        }
        if(correctAnswer.length){
          this.$set(this.resultInfo, 'correctAnswer', correctAnswer.join(','));
        }

        if(this.userSetting.quick_view){ //背题模式  获取详情的时候需要
          if(!type){
            this.infoCorrectAnswer = this.optionList[correctAnswerIndex];
            this.checkbox = correctCheckoutArr;
          }

        }else{
          if(data.userData.answer){
            this.checkbox = checkoutArr;
          }
        }
      }
      if(this.dataInfo.datasetData.type == '4') {  //4填空
        let answer;
        this.$set(this.resultInfo, 'correctAnswer', '');
        if(data.userData.answer){
          answer = JSON.parse(data.userData.answer);
          let correctAnswer = this.dataInfo.datasetDataOptions.map((item)=>{
            return item.name;
          }).join(',')
          this.$set(this.resultInfo, 'correctAnswer', correctAnswer);
        }
        data.datasetDataOptions.forEach((item,index)=>{
          if(answer[index]){
            this.$set(item,'myAnswer',answer[index]);
          }else{
            this.$set(item,'myAnswer','');
          }
        })
      }
      if(this.dataInfo.datasetData.type == '9') { //实操

        if(data.userData.answer){
          let urlArr = data.userData.answer.split('/');
          this.uploadName = urlArr[urlArr.length -1];
          this.uploadPath = data.userData.answer;



        }else{
          this.uploadName ='';
          this.uploadPath = '';
        }


      }

      if(this.dataInfo.datasetData.type == '8' || this.dataInfo.datasetData.type == '10') { //简答、填空、实验
        this.shortAnswer = data.userData.answer;


      }


    },

    collect(){  //收藏
      let params = {
        action:'userCollect',
        product_id:this.productId,
        type:'0',
        data_id:this.curData.data_id,
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.$Message.success(res);
        if(res == '取消收藏成功'){
          this.$set(this.dataInfo,'userCollect',{});
        }else{
          this.$set(this.dataInfo.userCollect,'id',1);
        }
      })
    },
    feedback(){
      this.feedbackModal = true;
    },
    confirm(){  //反馈确定
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          let params = {
            action:'feedback',
            product_id:this.productId,
            data_id:this.curData.data_id,
            title:this.formItem.title,
            content:this.formItem.feedback,
          };
          this.api.dataset.trainExecute(params).then((res)=>{
            this.$Message.success(this.$t('trainingcamp_train_feedback_success'));
            this.feedbackModal = false;
          })
        }
      })
    },
    saveRichData(data){
      this.formItem.feedback = data;
    },
    changeOnlyWrong(){
      this.getAnswerCard();
    },
    correction(){
      this.correctionModal = true;
      this.correctionInfo = JSON.parse(JSON.stringify(this.dataInfo));
      this.correctionTitle = '';
      this.formItem.feedback = '';
    },
    saveCurData(curData,data,name){
      console.log('eeeeeee',curData)
      data[name] = curData;
    },
    changeCorrect(data){  //修改正确选项
      if(this.correctionInfo.datasetData.type == '1' || this.correctionInfo.datasetData.type == '3'){ //单选
        if(data.is_correct == '0'){
          this.correctionInfo.datasetDataOptions.forEach((item)=>{
            item.is_correct = '0';
          });
          this.$nextTick(()=>{
            data.is_correct = '1';
          })

          console.log(data)
        }else{
          this.$nextTick(()=>{
            data.is_correct = '0';
          })
        }
      }else{
        data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
      }

    },
    addOption(){  //添加选项
      if(this.correctionInfo.datasetDataOptions.length == 6){
        this.$Message.warning(this.$t('trainingcamp_train_option_max'));
        return;
      }
      this.correctionInfo.datasetDataOptions.push({
        name:'',
        id:'0',
        is_correct:'0'
      })
    },
    deleOption(index){  //删除选项
      this.correctionInfo.datasetDataOptions.splice(index, 1);
    },
    confirmCorrection(){  //确认纠错
      if(!this.correctionTitle){
        this.$Message.warning('请输入纠错标题');
        return;
      }
      if(this.correctionTitle == 4 && !this.formItem.feedback){
        this.$Message.warning('请输入纠错内容');
        return;
      }
      // if(!this.correctionInfo.datasetData.name){  //标题
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
      //   return;
      // }
      // if(!this.correctionInfo.datasetDataOptions.length){ //选项
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
      //   return;
      // }
      // let len = this.correctionInfo.datasetDataOptions.filter((item)=>{
      //   return item.is_correct == '1';
      // }).length
      // if(!len){ //正确选项
      //   this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
      //   return;
      // }
      // for(let i=0;i<this.correctionInfo.datasetDataOptions.length;i++){
      //   if(!this.correctionInfo.datasetDataOptions[i].name){
      //     this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
      //     return;
      //     break;
      //   }
      // }
      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }

      let params = {
        data_id:this.correctionInfo.data_id,
        name:this.correctionInfo.datasetData.name,
        explain:this.correctionInfo.datasetData.explain,
        options:JSON.stringify(this.correctionInfo.datasetDataOptions),
        type:this.correctionInfo.datasetData.type,
        level:this.correctionInfo.datasetData.level,
        err_type:this.correctionTitle,
        err_message:this.formItem.feedback
      };

      this.api.dataset.correctionCreate(params).then((res)=>{
        this.correctionModal = false;
        this.$Message.success(this.$t('trainingcamp_train_correction_success'));
        // this.getInfo()
      })
    },
    changeCorrectionTit(data){
      this.correctionTitle = data;
    },
    downLoad(data){
      let name = data.split('/');
      name = name[name.length -1].split('.');
      name = name[0] + '.' + name[1];
      FileSaver.saveAs(data,name);
    },
  }
}
</script>

<style scoped lang="scss">
.train{
  padding: 20px;
  font-size: 14px;

  .train-cont{
    display: flex;
    justify-content: space-between;
    height: auto;
    overflow-y: auto;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    .left-top{
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;

      .tit{

        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;

        >span:nth-child(2){
          margin-left: 20px;
          font-size: 16px;
          font-weight: normal;
        }
        >span:nth-child(3){
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }

      }
      .name{
        margin:10px 0 ;
      }
      .cont{
        .option{
          margin-bottom: 10px;
        }
      }
      .bot{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn{
          button{
            margin-right: 10px;
          }
        }

        .op{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          >div{
            margin-left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            >img{
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }

        }
      }
    }
    .analysis{
      margin-top: 20px;
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;

      .answer{
        margin-bottom: 10px;

        .blue{
          color: #2d8cf0;
        }
        .green{
          color: #19be6b;
        }
        .red{
          color: #ed4014;
        }
      }
      .cont{
        //font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >span:nth-child(1){
          width: 50px;
        }
        >span:nth-child(2){
          flex: 1;
          width: 0;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
  }
  .right{
    width: 400px;
    .statistics{
      .tit{
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: bold;
      }
      .time{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .data{
        margin: 20px 0;
        border: 1px solid #dcdee2;
        text-align: center;
        background-color: #FFFFFF;
        .val{
          font-size: 35px;
          font-weight: bold;
          color:#2d8cf0;
          margin: 20px 0 10px 0;
        }
        .name{
          font-size: 30px;
        }
        .data-count{
          margin-top: 30px;
          margin-bottom: 20px;
          padding: 0 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;

      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;

        border-bottom: 1px solid #dcdee2;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div:nth-child(1){
          font-size: 20px;
          font-weight: bold;
        }

        >div:nth-child(2){

        }
      }
      .answer-card{
        margin: 20px 10px;
        height: 380px;
        overflow-y: auto;
        position: relative;
        .list{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            //border: 1px solid #2d8cf0;
          }
          >div.error{
            background-color: #ec808d;
            color:#FFFFFF;
          }
          >div.corret{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active{
            background-color: green;
            border: none;
          }
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
  }
  .option{
    font-size: 16px;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.red{
  color: #ed4014;
}
.green{
  color: #19be6b;
}
.correction{  //纠错
  height: 500px;
  overflow-y: auto;
  .type{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .tit,
  .option-item,
  .analysis{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .left{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .right{
      margin-left: 20px;
      flex: 1;
      border: 1px solid #dcdee2;

      .right-list{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right: 10px;
          border: 1px solid #dcdee2;
          border-radius: 4px;
          padding: 5px 10px;
          text-align: center;
          cursor: pointer;
        }
        .active{
          border: 1px solid #2d8cf0;
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }
    }
  }
  .analysis{
    margin-top: 20px;
  }
}
.cont-input{
  margin-bottom: 20px;
}
</style>
<style>
.feedback-richtxt{
  border:1px solid #dcdee2;
}
.train .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
.train-img-w img{
  width: 100%;
}
</style>
